import AuthDrawer from "../../components/AuthDrawer";
import useCallDataApi from "../../hooks/data";
import {useEffect, useState} from "react";
import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    FormControl,
    Grid, MenuItem, Select,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import Loading from "../../components/Loading";
import Button from "@mui/material/Button";
import {stringAvatar} from "../../utils/miscUtils";
import {useHistory} from "react-router-dom";
import {
    Person as PersonIcon,
    OpenInNew as OpenInNewIcon,
    CalendarToday as CalendarTodayIcon,
    AccessTime as AccessTimeIcon
} from '@mui/icons-material';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {hu} from "date-fns/locale";


const SavedCommissions = () => {
    const {getData: getUsers} = useCallDataApi('users')
    const {getData: getSavedCommissions} = useCallDataApi('saved-commissions')
    const [users, setUsers] = useState([])
    const [commissions, setCommissions] = useState([])
    const [loading, setLoading] = useState(true)
    const theme = useTheme()
    const today = new Date()
    const [searchQuery, setSearchQuery] = useState('')
    const [fromDate, setFromDate] = useState(new Date(`${today.getFullYear()}-01-01`))
    const [endDate, setEndDate] = useState(today)
    const history = useHistory()

    document.title = 'Ügyintézői jutalék'

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const [u, c] = await Promise.all([
                getUsers(),
                getSavedCommissions()
            ])
            if (u) setUsers(u)
            if (c) setCommissions(c)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenCommission = (commission) => {
        history.push(`/saved-commission/${commission?.id}`)
    }

    const getUser = id => users.find(u => u?.id === id)

    const Search = () => {
        return <Stack direction='row' alignItems='center' gap={2}>
            <TextField variant='standard' placeholder='Név tartalmazza...' value={searchQuery}
                       onChange={e => setSearchQuery(e.target.value)}/>
            {/*<Button variant='contained' onClick={() => setSearchQuery('')} startIcon={<SearchOffRoundedIcon/>}>Keresés törlése</Button>*/}
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
                <Stack spacing={2} direction='row' alignItems='center'>
                    <DatePicker
                        inputFormat="yyyy-MM-dd"
                        mask='____-__-__'
                        value={fromDate}
                        onChange={(v) => setFromDate(v)}
                        renderInput={(params) => <TextField name='from_date' variant='standard' {...params} />}
                    />
                    <Typography variant='body1' color='#38556A'>-</Typography>
                    <DatePicker
                        inputFormat="yyyy-MM-dd"
                        mask='____-__-__'
                        value={endDate}
                        onChange={(v) => setEndDate(v)}
                        renderInput={(params) => <TextField name='until_date' variant='standard' {...params} />}
                    />
                </Stack>
            </LocalizationProvider>
        </Stack>
    }

    return <AuthDrawer label='Mentett ügyintézői jutalék'>
        <Stack direction='row' justifyContent='space-between'
               sx={{background: '#fff', borderRadius: '8px', padding: '5px'}} marginBottom={2}>
            {Search()}
        </Stack>
        <Grid container spacing={2}>
            {commissions?.map(c => <Grid key={c.id} item xs={12} md={6} xl={6}>
                <Card sx={{maxWidth: 400, m: 2, p: 2, boxShadow: 3}}>
                    <CardContent>
                        <Stack direction="row" spacing={3} alignItems="center">
                            <Avatar {...stringAvatar(getUser(c?.user)?.name)} sx={{width: 70, height: 70}}/>
                            <Box>
                                <Typography variant="h6" color="primary" sx={{display: 'flex', alignItems: 'center'}}>
                                    {getUser(c?.user)?.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary"
                                            sx={{display: 'flex', alignItems: 'center'}}>
                                    <CalendarTodayIcon sx={{mr: 1}}/>
                                    {c?.year}-{c?.month}
                                </Typography>
                                <Typography variant="body2" color="textSecondary"
                                            sx={{display: 'flex', alignItems: 'center'}}>
                                    <AccessTimeIcon sx={{mr: 1}}/>
                                    Mentve: {c?.saved_at}
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                    <CardActions sx={{justifyContent: 'flex-end'}}>
                        <Button
                            variant="outlined"
                            onClick={() => handleOpenCommission(c)}
                            startIcon={<OpenInNewIcon/>}
                        >
                            Megnyitás
                        </Button>
                    </CardActions>
                </Card>
            </Grid>)}
        </Grid>

        <Loading isLoading={loading}/>

    </AuthDrawer>
}

export default SavedCommissions